"use client";

import { ContextMenuSeparator, ContextMenuShortcut, ContextMenuSub, ContextMenuSubContent, ContextMenuSubTrigger, ContextMenuItem } from "@/components/ui/context-menu";
import { Asset, Category, FileEntry, Project, Task } from "@palette.tools/model.client";
import { ChevronDownIcon, ChevronRightIcon, FolderInputIcon, Loader2 } from "lucide-react";
import React from "react";
import { TypographyXSmall } from "../../typography";
import { flushSync } from "react-dom";
import { LoadingIcon } from "@palette.tools/react.icons";
import { Emoji } from "emoji-picker-react";
import { EmojiDefaultAssetCategory } from "@palette.tools/utils/src/constants";


export const MoveFileContextMenuFragment = ({
  fileEntry,
  source,
  onClickFileMove,
  onGetMoveDestinations,
  dogsId,
}: {
  fileEntry: FileEntry,
  source: Category | Asset | Task,
  onClickFileMove?: (destination: Category | Asset | Task) => Promise<void>,
  onGetMoveDestinations?: (fileEntry: FileEntry) => Promise<{
    categories: Category[],
    assetsByCategory: Record<string, Asset[]>,
    tasksByAsset: Record<string, Task[]>,
  }>,
  dogsId: string,
}) => {

  const menuRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = React.useState(false);
  const [expandedItems, setExpandedItems] = React.useState<Set<string>>(new Set());
  const [shouldScroll, setShouldScroll] = React.useState(false);
  const [destinations, setDestinations] = React.useState<{
    categories: Category[],
    assetsByCategory: Record<string, Asset[]>,
    tasksByAsset: Record<string, Task[]>,
  }>({
    categories: [],
    assetsByCategory: {},
    tasksByAsset: {},
  });

  const throttledGetDestinations = React.useCallback(
    (() => {
      let lastCall = 0;
      return async () => {
        const now = Date.now();
        if (now - lastCall >= 30000 && onGetMoveDestinations) {
          lastCall = now;
          flushSync(() => {
            setIsLoading(true);
          });
          const result = await onGetMoveDestinations(fileEntry);
          flushSync(() => {
            setDestinations(result);
            setIsLoading(false);
          });
        }
      };
    })(),
    [onGetMoveDestinations, fileEntry]
  );

  React.useEffect(() => {
    if (isOpen) {
      throttledGetDestinations();
    }
  }, [isOpen, throttledGetDestinations]);

  React.useEffect(() => {
    if (!isLoading && destinations.categories.length > 0) {
      setHasLoadedOnce(true);
    }
  }, [isLoading, destinations.categories.length]);

  React.useEffect(() => {
    if (hasLoadedOnce && !isLoading && destinations.categories.length > 0) {
      const expanded = new Set<string>();
      
      if (source.id) {
        expanded.add(source.id);
        
        // Find parent relationships through the destination maps
        // For tasks, find parent asset
        const parentAssetId = Object.entries(destinations.tasksByAsset)
          .find(([_, tasks]) => tasks.some(task => task.id === source.id))?.[0];
        
        if (parentAssetId) {
          expanded.add(parentAssetId);
          
          // Then find parent category of the asset
          const parentCategoryId = Object.entries(destinations.assetsByCategory)
            .find(([_, assets]) => assets.some(asset => asset.id === parentAssetId))?.[0];
          
          if (parentCategoryId) {
            expanded.add(parentCategoryId);
          }
        } else {
          // For assets, find parent category
          const parentCategoryId = Object.entries(destinations.assetsByCategory)
            .find(([_, assets]) => assets.some(asset => asset.id === source.id))?.[0];
          
          if (parentCategoryId) {
            expanded.add(parentCategoryId);
          }
        }
      }
      
      setExpandedItems(expanded);
      setShouldScroll(true);
    }
  }, [hasLoadedOnce, isLoading, destinations, source]);

  React.useEffect(() => {
    if (shouldScroll && hasLoadedOnce && !isLoading && menuRef.current) {
      let attempts = 0;
      const maxAttempts = 10;
      
      const tryScroll = () => {
        const currentElement = menuRef.current?.querySelector(`[data-id="${source.id}"]`);
        if (currentElement) {
          currentElement.scrollIntoView({ block: 'center' });
          setShouldScroll(false);
        } else if (attempts < maxAttempts) {
          attempts++;
          setTimeout(tryScroll, 50);
        }
      };

      tryScroll();
    }
  }, [shouldScroll, hasLoadedOnce, isLoading, source.id]);

  const toggleExpand = (id: string) => {
    setExpandedItems(prev => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  };

  return <ContextMenuSub onOpenChange={setIsOpen}>
    <ContextMenuSubTrigger className="flex flex-row gap-[6px] place-items-center">
      <FolderInputIcon className="w-[12px] h-[12px]" />
      <TypographyXSmall>Move</TypographyXSmall>
    </ContextMenuSubTrigger>
    <ContextMenuSubContent 
      dogs-id={dogsId}
      dogs-selection-enabled={false}
      ref={menuRef}
      className="w-48 max-h-[300px] overflow-y-auto"
    >
      {isLoading ? (
        <ContextMenuItem>
          <LoadingIcon className="w-[12px] h-[12px]" />
        </ContextMenuItem>
      ) : (
        <>
          {destinations.categories.map(category => (
            <React.Fragment key={category.id}>
              <ContextMenuItem 
                dogs-id={dogsId}
                dogs-selection-enabled={false}
                data-id={category.id}
                className="flex flex-row gap-[6px] place-items-center relative group"
                onSelect={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleExpand(category.id);
                }}
              >
                <span className="w-[12px]" dogs-id={dogsId}>
                  {destinations.assetsByCategory[category.id]?.length > 0 && (
                    expandedItems.has(category.id) ? <ChevronDownIcon width={12} height={12} dogs-id={dogsId}/> : <ChevronRightIcon width={12} height={12} dogs-id={dogsId}/>
                  )}
                </span>
                <Emoji unified={category.data.emoji || EmojiDefaultAssetCategory} size={12} />
                <TypographyXSmall>{category.data.name}</TypographyXSmall>
                {source.id === category.id ? (
                  <span className="absolute right-2 bg-black/20 rounded px-[6px] py-[1px] text-[8px] font-medium">
                    ⭐
                  </span>
                ) : (
                  <button
                    className="invisible group-hover:visible absolute right-2 bg-black/20 rounded px-[6px] py-[1px] text-[8px] font-medium"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickFileMove?.(category);
                    }}
                  >
                    MOVE
                  </button>
                )}
              </ContextMenuItem>
              
              {expandedItems.has(category.id) && destinations.assetsByCategory[category.id]?.map(asset => (
                <React.Fragment key={asset.id}>
                  <ContextMenuItem 
                    dogs-id={dogsId}
                    dogs-selection-enabled={false}
                    data-id={asset.id}
                    className="flex flex-row gap-[6px] place-items-center pl-6 relative group"
                    onSelect={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleExpand(asset.id);
                    }}
                  >
                    <span className="w-[12px]" dogs-id={dogsId}>
                      {destinations.tasksByAsset[asset.id]?.length > 0 && (
                        expandedItems.has(asset.id) ? <ChevronDownIcon width={12} height={12} dogs-id={dogsId}/> : <ChevronRightIcon width={12} height={12} dogs-id={dogsId}/>
                      )}
                    </span>
                    <TypographyXSmall>{asset.data.name}</TypographyXSmall>
                    {source.id === asset.id ? (
                      <span className="absolute right-2 bg-black/20 rounded px-[6px] py-[1px] text-[8px] font-medium">
                        ⭐
                      </span>
                    ) : (
                      <button
                        className="invisible group-hover:visible absolute right-2 bg-black/20 rounded px-[6px] py-[1px] text-[8px] font-medium"
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickFileMove?.(asset);
                        }}
                      >
                        MOVE
                      </button>
                    )}
                  </ContextMenuItem>

                  {expandedItems.has(asset.id) && destinations.tasksByAsset[asset.id]?.map(task => (
                    <ContextMenuItem
                      dogs-id={dogsId}
                      dogs-selection-enabled={false}
                      data-id={task.id}
                      key={task.id}
                      className="pl-12 relative group"
                      onSelect={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <TypographyXSmall>{task.data.name}</TypographyXSmall>
                      {source.id === task.id ? (
                        <span className="absolute right-2 bg-black/20 rounded px-[6px] py-[1px] text-[8px] font-medium">
                          ⭐
                        </span>
                      ) : source.id !== task.id && (
                        <button
                          className="invisible group-hover:visible absolute right-2 bg-black/20 rounded px-[6px] py-[1px] text-[8px] font-medium"
                          onClick={(e) => {
                            e.stopPropagation();
                            onClickFileMove?.(task);
                          }}
                        >
                          MOVE
                        </button>
                      )}
                    </ContextMenuItem>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </>
      )}
    </ContextMenuSubContent>
  </ContextMenuSub>
}
