"use client";

import React from "react";

import { DogsSelectionChangeEvent } from "@palette.tools/react.dogs";


export const useFileTreeSelection = () => {
  const isSelectionBlocked = React.useRef(false);
  const isDeselectionWithinGroupBlocked = React.useRef(false);

  React.useEffect(() => {
    const handleSelectionChange = (e: DogsSelectionChangeEvent) => {

      if (isSelectionBlocked.current) {
        e.preventDefault();
        return;
      }

      if (e.detail.originalEvent?.type !== "mouseup") {
        return;
      };

      // Check if newSelection is smaller than oldSelection and all elements in new are in old
      const isSubset = () => {
        // If new selection is not smaller, it's not a pure deselection
        if (e.detail.newSelection.size >= e.detail.oldSelection.size) return false;
        
        // Check if every item in new selection exists in old selection
        for (const item of Array.from(e.detail.newSelection)) {
          if (!e.detail.oldSelection.has(item)) return false;
        }
        return true;
      };

      if (isSubset() && isDeselectionWithinGroupBlocked.current) {
        e.preventDefault();
        return;
      }

    };

    document.addEventListener('ondogsselectionchange', handleSelectionChange as EventListener);

    return () => {
      document.removeEventListener('ondogsselectionchange', handleSelectionChange as EventListener);
    };
  }, []);

  return {
    isSelectionBlocked,
    blockSelection: React.useCallback((blocked: boolean) => isSelectionBlocked.current = blocked, []),
    isDeselectionWithinGroupBlocked,
    blockDeselectionWithinGroup: React.useCallback((blocked: boolean) => isDeselectionWithinGroupBlocked.current = blocked, []),
  };

}

