"use client";

import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Input } from "../shadcn/components/ui/input";

import { FileEntry, Project, Workspace, transact, useAuth, usePermissions } from "@palette.tools/model.client";
import { getExtension, stripExtension } from "@palette.tools/utils";


export const RenameFileEntryModal: React.FC<{
  open: boolean,
  onClose: () => void,
  workspace: Workspace | null,
  project?: Project | null,
  file_entry: FileEntry | null,
}> = ({
  open,
  onClose,
  workspace,
  project,
  file_entry,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();

  // Permissions hooks.
  const { canEditWithinWorkspace, canEditWithinProject } = usePermissions({ workspace, project });
  const isWithinProject = !!project;
  const canEdit = (!isWithinProject && canEditWithinWorkspace) || (isWithinProject && canEditWithinProject);

  // Regular hooks
  const [name, setName] = useState(stripExtension(file_entry?.data.name));
  const extension = getExtension(file_entry?.data.name);
  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    if (open) {
      setName(stripExtension(file_entry?.data.name));
    }
  }, [file_entry, open]);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (!canEdit) {
      content = <TypographyMedium className="italic">Sorry, you are not allowed to rename files under this { project ? "project" : "workspace" }</TypographyMedium>
    }
    else {
      content = <div className="flex flex-row w-full items-start place-content-start py-3">
        <Input
          className="w-[300px]"
          autoFocus
          defaultValue={name}
          name="name"
          type="text"
          placeholder="Enter a document name..."
          onChange={(e) => setName(e.target.value)}
        />
      </div>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    !name.trim()
    || isLoadingProfile
    || !workspace
    || !file_entry
    || !canEdit
  );

  return <Modal
    open={open}
    header={"Rename File"}
    description={"Set the label for this file."}
    primaryButtonContent={isUpdating ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Updating"}</> : "Update"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      setName("");
      onClose?.();
    }}
    onSubmit={async () => {
      if (!submitDisabled) {
        setUpdating(true);
        transact(
          file_entry.update({ name: `${name}.${extension}` }),
        ).finally(() => {
          setUpdating(false);
          onClose();
        });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
