import { Asset, Category, FileEntry, Project, Task, usePermissions, Workspace } from "@palette.tools/model.client"
import { Group, Item } from "@palette.tools/react.dogs";
import { getExtension, stripExtension } from "@palette.tools/utils";
import { useMemo } from "react";


export interface FileTreeSelection {
  order?: string[],
  categories?: string[],
  assets?: string[],
  tasks?: string[],
  file_entries?: string[],
}


export type FileTreeModelCategory = {
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
  assets: Asset[],
  file_entries: FileEntry[],
  tasksByAsset?: Record<string, Task[]>,
  filesByAsset?: Record<string, FileEntry[]>,
  filesByTask?: Record<string, FileEntry[]>,
}
export function isFileTreeModelCategory(model: any): model is FileTreeModelCategory {
  return (model.category === null || model.category !== undefined) &&
    (model.asset === undefined) &&
    (model.task === undefined);
}


export type FileTreeModelAsset = {
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
  asset: Asset | null,
  tasks: Task[],
  file_entries: FileEntry[],
  filesByTask: Record<string, FileEntry[]>,
}
export function isFileTreeModelAsset(model: any): model is FileTreeModelAsset {
  return (model.category === null || model.category !== undefined) &&
    (model.asset === null || model.asset !== undefined) &&
    (model.task === undefined);
}


export type FileTreeModelTask = {
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
  asset: Asset | null,
  task: Task | null,
  file_entries: FileEntry[],
}
export function isFileTreeModelTask(model: any): model is FileTreeModelTask {
  return (model.category === null || model.category !== undefined) &&
    (model.asset === null || model.asset !== undefined) &&
    (model.task === null || model.task !== undefined);
}


export type FileTreeModel = FileTreeModelCategory | FileTreeModelAsset | FileTreeModelTask;


export interface FileTreeCallbacks {

  onSelect?: (selection: FileTreeSelection) => void,

  // File
  onFileOpen?: (fileEntry: FileEntry) => void,
  onFileGetLink?: (fileEntry: FileEntry) => string,
  onGetMoveDestinations?: (fileEntry: FileEntry) => Promise<{
    categories: Category[],
    assetsByCategory: Record<string, Asset[]>,
    tasksByAsset: Record<string, Task[]>,
  }>,
  onFileMove?: (entries: {fileEntry: FileEntry, source: Category | Asset | Task}[], destination: Category | Asset | Task) => Promise<void>,

  // Asset
  onAssetOpen?: (asset: Asset) => void,
  onAssetGetLink?: (asset: Asset) => string,

  // Task
  onTaskOpen?: (task: Task) => void,
  onTaskGetLink?: (task: Task) => string,

  // Category
  onCategoryOpen?: (category: Category) => void,
  onCategoryGetLink?: (category: Category) => string,

}


interface FileTreeItemBase {
  id: string,
  name: string,
}


export interface FileTreeFileEntry extends FileTreeItemBase {
  type: "file",
  extension: string,
  entity: FileEntry,
  source: Category | Asset | Task,
}


export interface FileTreeTask extends FileTreeItemBase {
  type: "task",
  entity: Task,
}


export interface FileTreeAsset extends FileTreeItemBase {
  type: "asset",
  entity: Asset,
}


export interface FileTreeCategory extends FileTreeItemBase {
  type: "category",
  entity: Category,
}


export type FileTreeItem = FileTreeFileEntry | FileTreeTask | FileTreeAsset | FileTreeCategory;


const constructFileEntryItem = (
  fileEntry: FileEntry,
  source: Category | Asset | Task,
  entitiesByItemId: Record<string, FileEntry | Task | Asset | Category>,
  lastModified: number,
  itemsById: Record<string, Item<FileTreeItem>>,
): {
  item: Item<FileTreeItem>,
  entitiesByItemId: Record<string, FileEntry | Task | Asset | Category>,
  lastModified: number,
  itemsById: Record<string, Item<FileTreeItem>>,
} => {

  const item = {
    __id: fileEntry.id,
    data: {
      type: "file" as const,
      id: fileEntry.id,
      name: stripExtension(fileEntry.data.name) || "",
      extension: getExtension(fileEntry.data.name) || "",
      entity: fileEntry,
      source,
    },
  }

  entitiesByItemId[item.__id] = fileEntry;
  lastModified = Math.max(lastModified, fileEntry.data.updated_at || 0);
  itemsById[item.__id] = item;
  return {item, entitiesByItemId, lastModified, itemsById};
}


const constructTaskItem = (
  model: FileTreeModelTask,
  entitiesByItemId: Record<string, FileEntry | Task | Asset | Category>,
  lastModified: number,
  itemsById: Record<string, Item<FileTreeItem>>,
): {
  item: Group<FileTreeItem> | null,
  entitiesByItemId: Record<string, FileEntry | Task | Asset | Category>,
  lastModified: number,
  itemsById: Record<string, Item<FileTreeItem>>,
} => {
  if (!model.task) return {item: null, entitiesByItemId, lastModified, itemsById};

  const item = {
    __id: model.task.id,
    data: {
      type: "task" as const,
      id: model.task.id,
      name: model.task.data.name || "",
      entity: model.task,
    },
    __children: [
      ...(model.file_entries || [])
        .sort((a, b) => (b.data.uploaded_at || 0) - (a.data.uploaded_at || 0))
        .map(fileEntry => {
          if (!model.task) return null;
          const {item: fileEntryItem, entitiesByItemId: newEntitiesByItemId, lastModified: newLastModified, itemsById: newItemsById} = constructFileEntryItem(fileEntry, model.task, entitiesByItemId, lastModified, itemsById);
          entitiesByItemId = newEntitiesByItemId;
          lastModified = newLastModified;
          itemsById = newItemsById;
          return fileEntryItem;
        }).filter(item => item !== null) as Item<FileTreeItem>[],
    ],
  }

  entitiesByItemId[item.__id] = model.task;
  lastModified = Math.max(lastModified, model.task.data.updated_at || 0);
  itemsById[item.__id] = item;
  return {item, entitiesByItemId, lastModified, itemsById};
}


const constructAssetItem = (
  model: FileTreeModelAsset,
  entitiesByItemId: Record<string, FileEntry | Task | Asset | Category>,
  lastModified: number,
  itemsById: Record<string, Item<FileTreeItem>>,
): {
  item: Group<FileTreeItem> | null,
  entitiesByItemId: Record<string, FileEntry | Task | Asset | Category>,
  lastModified: number,
  itemsById: Record<string, Item<FileTreeItem>>,
} => {
  if (!model.asset) return {item: null, entitiesByItemId, lastModified, itemsById};

  const item = {
    __id: model.asset.id,
    data: {
      type: "asset" as const,
      id: model.asset.id,
      name: model.asset.data.name || "",
      entity: model.asset,
    },
    __children: [
      ...(model.tasks || []).map(task => {
        const {item: taskItem, entitiesByItemId: newEntitiesByItemId, lastModified: newLastModified, itemsById: newItemsById} = constructTaskItem({...model, task, file_entries: model.filesByTask?.[task.id] || []}, entitiesByItemId, lastModified, itemsById);
        entitiesByItemId = newEntitiesByItemId;
        lastModified = newLastModified;
        itemsById = newItemsById;
        return taskItem;
      }).filter(item => item !== null) as Group<FileTreeItem>[],
      ...(model.file_entries || [])
        .sort((a, b) => (b.data.uploaded_at || 0) - (a.data.uploaded_at || 0))
        .map(fileEntry => {
          if (!model.asset) return null;
          const {item: fileEntryItem, entitiesByItemId: newEntitiesByItemId, lastModified: newLastModified, itemsById: newItemsById} = constructFileEntryItem(fileEntry, model.asset, entitiesByItemId, lastModified, itemsById);
          entitiesByItemId = newEntitiesByItemId;
          lastModified = newLastModified;
          itemsById = newItemsById;
          return fileEntryItem;
        }).filter(item => item !== null) as Item<FileTreeItem>[],
    ],
  }

  entitiesByItemId[item.__id] = model.asset;
  lastModified = Math.max(lastModified, model.asset.data.updated_at || 0);
  itemsById[item.__id] = item;
  return {item, entitiesByItemId, lastModified, itemsById};
}


const constructCategoryItem = (
  model: FileTreeModelCategory,
  entitiesByItemId: Record<string, FileEntry | Task | Asset | Category>,
  lastModified: number,
  itemsById: Record<string, Item<FileTreeItem>>,
): {
  item: Group<FileTreeItem> | null,
  entitiesByItemId: Record<string, FileEntry | Task | Asset | Category>,
  lastModified: number,
  itemsById: Record<string, Item<FileTreeItem>>,
} => {
  if (!model.category) return {item: null, entitiesByItemId, lastModified, itemsById};

  const item = {
    __id: model.category.id,
    data: {
      type: "category" as const,
      id: model.category.id,
      name: model.category.data.name || "",
      entity: model.category,
    },
    __children: [
      ...(model.assets || []).map(asset => {
        const {item: assetItem, entitiesByItemId: newEntitiesByItemId, lastModified: newLastModified, itemsById: newItemsById} = constructAssetItem({...model, asset, tasks: model.tasksByAsset?.[asset.id] || [], filesByTask: model.filesByTask || {}, file_entries: model.filesByAsset?.[asset.id] || []}, entitiesByItemId, lastModified, itemsById);
        entitiesByItemId = newEntitiesByItemId;
        lastModified = newLastModified;
        itemsById = newItemsById;
        return assetItem;
      }).filter(item => item !== null) as Group<FileTreeItem>[],
      ...(model.file_entries || [])
        .sort((a, b) => (b.data.uploaded_at || 0) - (a.data.uploaded_at || 0))
        .map(fileEntry => {
          if (!model.category) return null;
        const {item: fileEntryItem, entitiesByItemId: newEntitiesByItemId, lastModified: newLastModified, itemsById: newItemsById} = constructFileEntryItem(fileEntry, model.category, entitiesByItemId, lastModified, itemsById);
        entitiesByItemId = newEntitiesByItemId;
        lastModified = newLastModified;
        itemsById = newItemsById;
        return fileEntryItem;
      }).filter(item => item !== null) as Item<FileTreeItem>[],
    ],
  }
  entitiesByItemId[item.__id] = model.category;
  lastModified = Math.max(lastModified, model.category.data.updated_at || 0);
  itemsById[item.__id] = item;
  return {item, entitiesByItemId, lastModified, itemsById};
}


const constructItems = (model: FileTreeModel): {
  items: (Item<FileTreeItem>)[],
  itemsById: Record<string, Item<FileTreeItem>>,
  entitiesByItemId: Record<string, FileEntry | Task | Asset | Category>,
  lastModified: number,
} => {

  let entitiesByItemId: Record<string, FileEntry | Task | Asset | Category> = {};
  let lastModified = 0;
  let itemsById: Record<string, Item<FileTreeItem>> = {};

  if (!model.workspace || !model.project) return {items: [], itemsById: {}, entitiesByItemId, lastModified};

  if (isFileTreeModelTask(model)) {
    const {item, entitiesByItemId: newEntitiesByItemId, lastModified: newLastModified, itemsById: newItemsById} = constructTaskItem(model, entitiesByItemId, lastModified, itemsById);
    if (!item) return {items: [], itemsById, entitiesByItemId, lastModified};
    itemsById = newItemsById;
    entitiesByItemId = newEntitiesByItemId;
    lastModified = newLastModified;
    return {
      items: item.__children,
      itemsById,
      entitiesByItemId,
      lastModified,
    };
  }

  if (isFileTreeModelAsset(model)) {
    const {item, entitiesByItemId: newEntitiesByItemId, lastModified: newLastModified, itemsById: newItemsById} = constructAssetItem(model, entitiesByItemId, lastModified, itemsById);
    if (!item) return {items: [], itemsById, entitiesByItemId, lastModified};
    itemsById = newItemsById;
    entitiesByItemId = newEntitiesByItemId;
    lastModified = newLastModified;
    return {
      items: item.__children,
      itemsById,
      entitiesByItemId,
      lastModified,
    };
  }

  if (isFileTreeModelCategory(model)) {
    const {item, entitiesByItemId: newEntitiesByItemId, lastModified: newLastModified, itemsById: newItemsById} = constructCategoryItem(model, entitiesByItemId, lastModified, itemsById);
    if (!item) return {items: [], itemsById, entitiesByItemId, lastModified};
    itemsById = newItemsById;
    entitiesByItemId = newEntitiesByItemId;
    lastModified = newLastModified;
    return {
      items: item.__children,
      itemsById,
      entitiesByItemId,
      lastModified,
    };
  }

  return {items: [], itemsById, entitiesByItemId, lastModified};

}


export const useFileTree = (model: FileTreeModel) => {

  const perms = usePermissions({
    workspace: model.workspace,
    project: model.project,
    category: model.category,
    asset: isFileTreeModelAsset(model) || isFileTreeModelTask(model) ? model.asset : undefined,
    task: isFileTreeModelTask(model) ? model.task : undefined,
  });

  return useMemo(() => {
    const {items, itemsById, entitiesByItemId, lastModified} = constructItems(model);
    return {items, itemsById, entitiesByItemId, lastModified, perms};
  }, [model, perms]);
}
