"use client";

import { LoadingIcon } from "@palette.tools/react.icons";
import { formatTimestamp, getTimeSinceAbbreviated } from "@palette.tools/utils";
import EmojiPicker, { EmojiClickData, Theme as EmojiTheme } from "emoji-picker-react";
import { ArrowDownIcon, MoreHorizontalIcon, PencilIcon, SendHorizonalIcon, SmileIcon, Trash2Icon, FilmIcon } from "lucide-react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import ImageFallback from "../image/ImageFallback";
import { BlockingModal, EditCommentModal } from "../modals";
import { Button } from "../shadcn/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../shadcn/components/ui/dropdown-menu";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/components/ui/popover";
import { cn } from "../shadcn/lib/utils";
import { TypographyMedium, TypographyMuted, TypographySmall } from "../typography";
import { ShortcutManager, overrideElement, useShortcutRef } from "../utils";
import { Comment, Project, UserProfile, Workspace, id as uuid, transact, useAuth, Category, Task, getPermissions, FileEntry, Asset } from "@palette.tools/model.client";
import { getProfileName } from "@palette.tools/model";
import { getMessageGroupsFromComments } from "@palette.tools/model/src/Comment";
import { delete_entity } from "@palette.tools/api.client";
import { Separator } from "../shadcn/components/ui/separator";


const CommentItem = overrideElement<'div', {
  comment: Comment,
  canEdit: boolean,
  canDelete: boolean,
  isSolo: boolean,
  showAttachments?: boolean,
  onClickComment?: (comment: Comment) => void,
  onClickDeleteComment?: (comment: Comment) => void,
  onClickEditComment?: (comment: Comment) => void,
  onClickFile?: (fileEntry: FileEntry) => void,
}>(({className, props, customProps: {
  comment,
  canEdit,
  canDelete,
  isSolo,
  showAttachments = false,
  onClickComment,
  onClickDeleteComment,
  onClickEditComment,
  onClickFile,
}}) => {

  const { edited } = comment.data;
  const [timeAgo, setTimeAgo] = useState<string>(comment.data.updated_at ? getTimeSinceAbbreviated(comment.data.updated_at) : "");

  let items: React.ReactNode[] = [];

  if (canEdit) {
    const item = <DropdownMenuItem
      key="edit"
      onClick={(e) => {
        onClickEditComment?.(comment);
      }}>
      <PencilIcon width={16} height={16} />&nbsp;&nbsp;<span>Edit</span>
    </DropdownMenuItem>;
    items.push(item);
  }

  if (canDelete) {
    const item = <DropdownMenuItem
      key="delete"
      onClick={(e) => {
        onClickDeleteComment?.(comment);
      }}>
      <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
    </DropdownMenuItem>;
    items.push(item);
  }

  useEffect(() => {
    setTimeAgo(comment.data.updated_at ? getTimeSinceAbbreviated(comment.data.updated_at) : "")
  }, [comment.data.updated_at]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeAgo(comment.data.updated_at ? getTimeSinceAbbreviated(comment.data.updated_at) : "");
    }, 60000); // Update every 60 seconds (1 minute)

    // Cleanup function for unmounting
    return () => clearInterval(intervalId);
  });

  const { onClick, ...rest } = props;

  const attachments = (
    (comment.data.attachments || []).map(x => (comment.links.file_entry || []).find(y => y.id === x.id)).filter(Boolean) as FileEntry[]
  ).filter(x => x?.data.active && (x?.data.mime_type?.startsWith('image/') || x?.data.mime_type?.startsWith('video/')));

  return <div
    className={cn(
      className,
      "whitespace-pre-wrap w-full flex flex-col group/commentitem break-words",
      isSolo ? "" : "hover:bg-muted/60",
      onClickComment ? "cursor-pointer" : "",
    )}
    onClick={e => { onClickComment?.(comment); onClick?.(e) }}
    {...rest}
  >
    <div className="flex flex-row w-full">
      <div className="flex-1 w-full">
        <span className="break-words cursor-text">{comment.data.message}</span>
        <span className="text-muted-foreground italic text-xs select-none">
          {edited ? ` (edited ${timeAgo})` : ""}
        </span>
      </div>
      {items.length > 0 ? <div className="pl-[4px] flex flex-col" >
        <DropdownMenu>
          <DropdownMenuTrigger className="items-start content-start">
            <MoreHorizontalIcon className={cn(
              "opacity-0 group-hover/commentitem:opacity-100",
              isSolo ? "group-hover/commentgroup:opacity-100" : "",
            )}/>
          </DropdownMenuTrigger>
          <DropdownMenuContent>{items}</DropdownMenuContent>
        </DropdownMenu>
        <div className="flex-1" />
      </div> : null}
    </div>
    {showAttachments && attachments.length > 0 ? (
      <div className="flex flex-col gap-2 mt-2">
        {attachments.map((attachment, index) => (
          <div
            key={`attachment-${index}`}
            className="cursor-pointer"
            onClick={() => onClickFile?.(attachment)}
          >
            {attachment.data.mime_type?.startsWith('image/') ? (
              <img
                src={attachment.data.url}
                alt={`Attachment ${index + 1}`}
                className="max-w-[200px] max-h-[200px] w-auto h-auto rounded object-cover"
              />
            ) : attachment.data.mime_type?.startsWith('video/') ? (
              <div className="bg-muted rounded-lg w-[200px] h-[113px] flex items-center justify-center">
                <FilmIcon className="w-12 h-12 text-muted-foreground" />
              </div>
            ) : null}
          </div>
        ))}
      </div>
    ) : null}
  </div>

}, [
  'comment',
  'canEdit',
  'canDelete',
  'isSolo',
  'onClickComment',
  'onClickDeleteComment',
  'onClickEditComment',
  'onClickFile',
  'showAttachments',
  'onClickFile',
]);


const MessageGroupItem: React.FC<{
  users: UserProfile[],
  comments: Comment[],
  canEdit: boolean,
  canDelete: boolean,
  showAttachments?: boolean,
  onClickComment?: (comment: Comment) => void,
  onClickDeleteComment?: (comment: Comment) => void,
  onClickEditComment?: (comment: Comment) => void,
  onClickFile?: (fileEntry: FileEntry) => void,
}> = ({
  users,
  comments,
  canEdit,
  canDelete,
  showAttachments = false,
  onClickComment,
  onClickDeleteComment,
  onClickEditComment,
  onClickFile,
}) => {

  if (!comments || comments.length < 1) {
    return null;
  }

  const lowestFrame = Math.min(...comments.map(x => x.data.frame || -1));
  const frame = lowestFrame > 0 ? lowestFrame : undefined;

  const created_by = comments[0]?.data.created_by;
  const user = users.find((user) => user.id === created_by);
  const profilePic = created_by === "server-discord-bot" ? "/assets/logo_huge.png" : user?.data.image_url;
  const username = created_by === "server-discord-bot" ? "Palette Discord Bot" : getProfileName(user);
  const timestamp = comments[0]?.data.created_at;

  return (
    <div
      className={cn(
        "flex flex-col gap-y-2 items-start w-full group/commentgroup px-4 py-2",
        comments.length < 2 ? "hover:bg-muted/40" : "",
        comments.length < 2 && onClickComment ? "cursor-pointer" : "",
      )}
      onClick={_ => comments.length === 1 && onClickComment?.(comments[0]!)}
    >
      <div className="flex flex-row gap-x-[10px] items-center content-center">
      <ImageFallback
        className="rounded-full pointer-events-none"
        src={profilePic}
        width="35"
        height="35"
        alt={`Profile pic of ${username}`}
      />
      <div className="flex flex-col">
        <TypographySmall className="font-extrabold">{username}</TypographySmall>
        {timestamp && <TypographyMuted>{formatTimestamp(timestamp)}</TypographyMuted>}
      </div>
      </div>
      <div className="flex flex-col gap-x-[10px] flex-1 min-h-0 w-full">
        <span className="text-primary">{frame ? "Frame " + frame : null}</span>
        <div className="flex flex-col gap-x-[10px] w-full break-words">
          {comments.map((comment, i) => <CommentItem
            key={"comment" + i}
            comment={comment}
            canEdit={canEdit}
            canDelete={canDelete}
            isSolo={comments.length < 2}
            showAttachments={showAttachments}
            onClickComment={onClickComment}
            onClickDeleteComment={onClickDeleteComment}
            onClickEditComment={onClickEditComment}
            onClickFile={onClickFile}
          />)}
        </div>
      </div>
    </div>
  );
};


export const CommentBox: React.FC<{
  comments: Comment[],
  users: UserProfile[],
  workspace: Workspace | null,
  project: Project | null,
  parent?: Category | Asset | Task | FileEntry | null,
  currentFrame?: number,
  className?: string,
  showAttachments?: boolean,
  onClickComment?: (comment: Comment) => void,
  onClickFile?: (fileEntry: FileEntry) => void,
  groupInterval?: number | null,
  sortByFrame?: boolean,
  shortcutManager?: ShortcutManager,
}> = ({
  comments,
  users,
  workspace,
  project,
  parent,
  currentFrame = 0,
  className,
  showAttachments = false,
  onClickComment,
  onClickFile,
  groupInterval = 300000,
  sortByFrame = false,
  shortcutManager,
}) => {

  // Firestore state.
  const { profile } = useAuth();
  const [ submitting, setSubmitting ] = useState(false);
  const [ messageGroups, setMessageGroups ] = useState(getMessageGroupsFromComments(comments));
  const [ targetComment, setTargetComment ] = useState<Comment | undefined>();
  const { canEditComment: canEditTargetComment } = getPermissions({
    profile,
    workspace,
    project,
    category: parent?.key === "category" ? parent : undefined,
    task: parent?.key === "task" ? parent: undefined,
    comment: targetComment,
  });
  const [isDeleting, setDeleting] = useState(false);
  const [isEmojiMenuOpen, setEmojiMenuOpen] = useState(false);
  const emojiTriggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setMessageGroups(getMessageGroupsFromComments(comments, { sortByFrame, interval: groupInterval } ));
  }, [comments, groupInterval])


  // Scrollbar state.
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const [showDownArrow, setShowDownArrow] = useState(false);
  const [hideScrollbar, setHideScrollbar] = useState(true);
  const userJustPosted = useRef(false);
  const [userIsScrolling, setUserIsScrolling] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  // Input state.
  const [newMessage, setNewMessage] = useState("");

  // Frame checked state.
  const [isCommentOnFrameChecked, setCommentOnFrameChecked] = useState(true);


  const isScrolledToBottom = () => {
    const commentsContainer = commentsContainerRef.current;
    if (commentsContainer) {
      const { scrollTop, scrollHeight, clientHeight } = commentsContainer;
      return scrollTop + clientHeight >= scrollHeight - 10;
    }
    return false;
  };

  const handleScroll = () => {
    if (isScrolledToBottom()) {
      setShowDownArrow(false);
      setHideScrollbar(true);
      setUserIsScrolling(false);
    } else {
      setShowDownArrow(true);
      setHideScrollbar(false);
      setUserIsScrolling(true);
    }
  };

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.addEventListener('scroll', handleScroll);
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
    return () => {
      if (commentsContainerRef.current) {
        commentsContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [commentsContainerRef]);


  const isInitialScrollCompleteRef = useRef(false);

  const performInitialScroll = useCallback(() => {
    let retryCount = 0;
    const maxRetries = 10;

    const scrollToBottom = () => {
      if (commentsContainerRef.current && !isInitialScrollCompleteRef.current) {
        const { scrollHeight, clientHeight } = commentsContainerRef.current;
        const scrollToPosition = scrollHeight - clientHeight;
        
        commentsContainerRef.current.scrollTop = scrollToPosition;
        
        // Check if the scroll was successful
        if (commentsContainerRef.current.scrollTop === scrollToPosition) {
          isInitialScrollCompleteRef.current = true;
        } else if (retryCount < maxRetries) {
          retryCount++;
          requestAnimationFrame(scrollToBottom);
        } else {
          console.error('Failed to set initial scroll position after max retries');
        }
      }
    };

    requestAnimationFrame(scrollToBottom);
  }, []);

  useEffect(() => {
    if (isInitialScrollCompleteRef.current) return;
    performInitialScroll();
  }, [comments, performInitialScroll]);

  useEffect(() => {
    if (commentsContainerRef.current) {
      if (comments && comments.length > 0) {
        if (userJustPosted.current) {
          commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
          userJustPosted.current = false;
        }
        if (!userIsScrolling) {
          commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
        }
      }
      setShowDownArrow(!isScrolledToBottom());
    }
  }, [comments, commentsContainerRef.current, textAreaRef]);

  const handleDownArrowClick = () => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
    setShowDownArrow(false); // Hide the DownArrow
  };

  const onSubmit = (latestText?: string) => {

    const message = latestText || newMessage;

    if (!profile || !workspace || !project || !parent || !(message.trim())) return;

    userJustPosted.current = true;

    setNewMessage("");
    setSubmitting(true);
    const id = uuid();
    transact(
      Comment.create({ id, frame: isCommentOnFrameChecked && currentFrame !== 0 ? currentFrame : null, edited: false, message }, { after: (key, id) => [
        ...workspace.link(key, id),
        ...project.link(key, id),
      ]}),
      parent.link("comment", id),
    ).finally(() => {
      setSubmitting(false);
    })

  }

  const generalComments = comments.filter(x => !x.data.frame || x.data.frame < 1);
  const frameComments = comments.filter(x => x.data.frame && x.data.frame > 0);

  const commentGroupItems = messageGroups.map((comments, i) => {
    const { canEditComment, canDeleteComment } = getPermissions({
      profile,
      workspace,
      project,
      category: parent?.key === "category" ? parent : undefined,
      task: parent?.key === "task" ? parent: undefined,
      comment: comments[0],
    });

    const isLastGeneralComment = generalComments.length > 0 && i === generalComments.length - 1;

    return <React.Fragment key={"message-group" + i}><MessageGroupItem
      users={users}
      comments={comments}
      canEdit={canEditComment}
      canDelete={canDeleteComment}
      onClickDeleteComment={(comment) => {
        setDeleting(true);
        delete_entity(comment.key, comment.id).finally(async () => {
          setDeleting(false);
        });
      }}
      onClickEditComment={(comment) => setTargetComment(comment)}
      onClickComment={onClickComment}
      onClickFile={onClickFile}
      showAttachments={showAttachments}
    />
    {isLastGeneralComment && frameComments.length > 0 ? <div key="separator" className="p-4"><Separator key={`separator-${i}`} /></div> : null}
    </React.Fragment>
  })


  return <>
    {isDeleting ? <BlockingModal delay={1000}>Deleting...</BlockingModal> : null}

    {!!targetComment ? <EditCommentModal
      open={!!targetComment}
      onClose={() => setTargetComment(undefined)}
      enabled={canEditTargetComment}
      comment={targetComment}
    /> : undefined}

    <div className={cn("flex flex-col rounded-md h-full relative min-h-0 min-w-[480px] p-[10px] gap-y-[10px]", className)}>

      {showDownArrow && (
        <div
          className="absolute t-[20px] r-[40px] cursor-pointer"
          style={{ position: 'absolute', top: 20, right: 40, cursor: 'pointer' }}
          onClick={handleDownArrowClick}
        >
          <ArrowDownIcon size={20} className="stroke-muted-foreground" />
        </div>
      )}

      <div
        ref={useShortcutRef(shortcutManager, commentsContainerRef)}
        className={cn("overflow-y-scroll flex flex-col w-full py-[10px] flex-1 min-h-0 rounded-xl bg-muted/25 focus:ring-0 focus:outline-0", hideScrollbar ? 'hide-scrollbar' : '')}
      >
        <div className="flex flex-col">
          {commentGroupItems}
        </div>
      </div>

      <div className="flex-shrink-0">
        <form
          className="flex flex-col w-full h-auto gap-x-[10px] p-2 rounded-lg border-border border-[1px] bg-muted/50 focus-within:ring-2 ring-ring"
          onSubmit={(e) => {e.preventDefault() ; e.stopPropagation() ; onSubmit()}}
        >
          <textarea
            ref={textAreaRef}
            className="resize-none w-full bg-transparent outline-none ring-none border-none"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                onSubmit(e.currentTarget.value);
              }
            }}
            placeholder={currentFrame && currentFrame > 0
              ? isCommentOnFrameChecked
                ? "Frame comment"
                : "General comment"
              : "Use this for questions, notes, jokes... anything!"
            }
            rows={3}
          />
          <div className="flex flex-row items-center content-center">

            {!!currentFrame && currentFrame > 0 ? (
              <div className="flex flex-row items-center content-center gap-x-2 cursor-pointer select-none" onClick={_ => setCommentOnFrameChecked(!isCommentOnFrameChecked)}>
                <div aria-checked={isCommentOnFrameChecked} className="w-[15px] h-[15px] border-muted-foreground border-2 rounded-sm flex flex-row place-items-center place-content-center aria-checked:border-primary aria-checked:bg-primary/30">
                  <div aria-checked={isCommentOnFrameChecked} className="opacity-0 aria-checked:opacity-100 w-[4px] h-[4px] rounded-full bg-primary" />
                </div>
                <span aria-checked={isCommentOnFrameChecked} className="text-xs text-muted-foreground aria-checked:text-primary">Frame {currentFrame}</span>
              </div>
            ) : null}

            <div className="flex-1" />

            <Popover open={true}>

              <PopoverTrigger
                ref={emojiTriggerRef}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEmojiMenuOpen(!isEmojiMenuOpen);
                }}
              >
                <SmileIcon width={24} height={24} />
              </PopoverTrigger>

              <PopoverContent
                hidden={!isEmojiMenuOpen}
                className="pointer-events-auto bg-transparent border-none outline-none ring-none p-0 m-0 shadow-none w-[350px] h-[450px]"
                onInteractOutside={e => {
                  if (emojiTriggerRef.current && emojiTriggerRef.current.contains(e.target as Node)) {
                    return;
                  }
                  setEmojiMenuOpen(false);
                }}
              >
                <EmojiPicker
                  lazyLoadEmojis
                  autoFocusSearch
                  theme={EmojiTheme.DARK}
                  onEmojiClick={(newEmoji: EmojiClickData) => {
                    if (textAreaRef.current) {
                      const textarea = textAreaRef.current;
                      const start = textarea.selectionStart;
                      const end = textarea.selectionEnd;

                      // Insert the emoji
                      const updatedText =
                          textarea.value.substring(0, start) +
                          newEmoji.emoji +
                          textarea.value.substring(end);

                      textarea.value = updatedText;

                      // Move the cursor forward
                      textarea.selectionStart = textarea.selectionEnd = start + newEmoji.emoji.length;

                      textarea.focus(); // Keep the focus on the textarea

                      setNewMessage(updatedText);
                    }
                  }}
                />
              </PopoverContent>
            </Popover>
          </div>
        </form>
      </div>
    </div>
  </>
};
