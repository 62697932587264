import React, { useMemo, useRef, useState } from "react";

import { Item, RenderableChild, RenderableGroup, useDogs } from "@palette.tools/react.dogs";
import { cn } from "@/lib/utils";
import { FileTreeCallbacks, FileTreeFileEntry, FileTreeItem, FileTreeModel, FileTreeSelection, useFileTree } from "./model";
import { BlueFolderIcon } from "@palette.tools/react.icons";
import { TypographyXSmall } from "@palette.tools/react/src/typography";
import TextFill from "@palette.tools/react/src/typography/TextFill";
import { TooltipProvider } from "@/components/ui/tooltip";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuTrigger,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuSeparator,
} from "@/components/ui/context-menu";
import { DeleteMultiModal, RenameAssetModal, RenameCategoryModal, RenameFileEntryModal, RenameTaskModal } from "@palette.tools/react/src/modals";
import { EyeOpenIcon } from "@radix-ui/react-icons";
import { DownloadIcon, FolderEditIcon, FolderInputIcon, LinkIcon, TrashIcon } from "lucide-react";
import { Asset, Category, downloadFile, FileEntry, Task } from "@palette.tools/model.client";
import { MoveFileContextMenuFragment } from "./menus";
import { useFileTreeSelection } from "./selection";

interface FileItemProps extends React.HTMLAttributes<HTMLDivElement> {
  item: RenderableChild<FileTreeFileEntry>,
  source: Category | Asset | Task | null,
  isMoveDisabled?: boolean,
  isDownloadDisabled?: boolean,
  onContextMenuOpenChanged?: (open: boolean) => void,
  onOpen?: () => void,
  onClickCopyLink?: () => void,
  onClickDownload?: () => void,
  onClickRename?: () => void,
  onClickFileMove?: (destination: Category | Asset | Task) => Promise<void>,
  onClickDelete?: () => void,
  onGetMoveDestinations?: (fileEntry: FileEntry) => Promise<{
    categories: Category[],
    assetsByCategory: Record<string, Asset[]>,
    tasksByAsset: Record<string, Task[]>,
  }>,
}


export const FileItem = React.forwardRef<HTMLDivElement, FileItemProps>(({
  item,
  source,
  isMoveDisabled = false,
  isDownloadDisabled = false,
  onContextMenuOpenChanged,
  onOpen,
  onClickCopyLink,
  onClickDownload,
  onClickRename,
  onClickFileMove,
  onClickDelete,
  onGetMoveDestinations,
  ...props
}, ref) => {

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const content = <div 
    ref={ref}
    data-selected={item.isSelected}
    {...props}
    className={cn(
      props.className,

      // Base
      "flex flex-col items-center place-content-start gap-2 w-[100px] h-[130px] p-[2px] rounded-md",

      // Hover
      "hover:bg-zinc-700",

      // Selected
      "data-[selected=true]:bg-zinc-700",

    )}
    onDoubleClick={onOpen}
  >
    <div className="h-[80px] w-[80px] min-h-[80px] min-w-[80px] flex items-center place-content-center">
      <div className="h-[60px] w-[60px] min-h-[60px] min-w-[60px] bg-muted/80 flex items-center place-content-center rounded-md ">
        <div className="w-2/3 h-2/3 flex items-center place-content-center text-muted-foreground select-none">
          <TextFill>{item.item.data.extension}</TextFill>
        </div>
      </div>
    </div>
    <TypographyXSmall className="font-inter text-xs/[14px] font-regular line-clamp-2 text-ellipsis break-all">
      {item.item.data.name}
    </TypographyXSmall>
  </div>

  const contextMenuContent = isContextMenuOpen ? <ContextMenuContent
    dogs-id={item.item.__id}
  >
    <ContextMenuItem onSelect={onOpen} className="flex flex-row gap-[6px] place-items-center">
      <EyeOpenIcon className="w-[12px] h-[12px]" />
      <TypographyXSmall>Open</TypographyXSmall>
    </ContextMenuItem>
    <ContextMenuSeparator />
    <ContextMenuItem onSelect={onClickCopyLink} className="flex flex-row gap-[6px] place-items-center">
      <LinkIcon className="w-[12px] h-[12px]" />
      <TypographyXSmall>Copy Link</TypographyXSmall>
    </ContextMenuItem>
    <ContextMenuItem onSelect={onClickDownload} className="flex flex-row gap-[6px] place-items-center">
      <DownloadIcon className="w-[12px] h-[12px]" />
      <TypographyXSmall>Download</TypographyXSmall>
    </ContextMenuItem>
    <ContextMenuSeparator />
    <ContextMenuItem onSelect={onClickRename} className="flex flex-row gap-[6px] place-items-center">
      <FolderEditIcon className="w-[12px] h-[12px]" />
      <TypographyXSmall>Rename</TypographyXSmall>
    </ContextMenuItem>
    {source && <MoveFileContextMenuFragment
      fileEntry={item.item.data.entity}
      source={source}
      onGetMoveDestinations={onGetMoveDestinations}
      onClickFileMove={onClickFileMove}
      dogsId={item.item.__id}
    />}
    <ContextMenuSeparator />
    <ContextMenuItem onSelect={onClickDelete} className="flex flex-row gap-[6px] place-items-center">
      <TrashIcon className="w-[12px] h-[12px] text-destructive" />
      <TypographyXSmall className="text-destructive">Delete</TypographyXSmall>
    </ContextMenuItem>
  </ContextMenuContent> : null;

  return <ContextMenu
    onOpenChange={isOpen => { setIsContextMenuOpen(isOpen); onContextMenuOpenChanged?.(isOpen) }}
  >
    <ContextMenuTrigger>
      {content}
    </ContextMenuTrigger>
    {contextMenuContent}
  </ContextMenu>

})


interface FolderItemProps extends React.HTMLAttributes<HTMLDivElement> {
  item: RenderableGroup<FileTreeFileEntry>,
  isContextMenuOpen?: boolean,
  onContextMenuOpenChanged?: (open: boolean) => void,
  onOpen?: () => void,
  onClickCopyLink?: () => void,
  onClickRename?: () => void,
  onClickDelete?: () => void,
}


export const FolderItem = React.forwardRef<HTMLDivElement, FolderItemProps>(({
  item,
  onContextMenuOpenChanged,
  onClickDelete,
  onOpen,
  onClickCopyLink,
  onClickRename,
  ...props
}, ref) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const content = <div
    ref={ref}
    data-selected={item.isSelected}
    {...props}
    className={cn(
      props.className,

      // Base
      "flex flex-col items-center place-content-start w-[100px] h-[130px] p-[2px] rounded-md",

      // Hover
      "hover:bg-zinc-700",

      // Selected
      "data-[selected=true]:bg-zinc-700",

    )}
    onDoubleClick={onOpen}
  >
    <BlueFolderIcon className="h-[80px] w-[80px] min-w-[80px] min-h-[80px]" />
    <TypographyXSmall className="font-inter text-xs/[14px] font-regular line-clamp-2 text-ellipsis break-all">
      {item.group.data.name}
    </TypographyXSmall>
    <div className="flex-1" />
  </div>

  const contextMenuContent = isContextMenuOpen ? <ContextMenuContent
    dogs-id={item.group.__id}
  >
    <ContextMenuItem onSelect={onOpen} className="flex flex-row gap-[6px] place-items-center">
      <EyeOpenIcon className="w-[12px] h-[12px]" />
      <TypographyXSmall>Open</TypographyXSmall>
    </ContextMenuItem>
    <ContextMenuSeparator />
    <ContextMenuItem onSelect={onClickCopyLink} className="flex flex-row gap-[6px] place-items-center">
      <LinkIcon className="w-[12px] h-[12px]" />
      <TypographyXSmall>Copy Link</TypographyXSmall>
    </ContextMenuItem>
    <ContextMenuSeparator />
    <ContextMenuItem onSelect={onClickRename} className="flex flex-row gap-[6px] place-items-center">
      <FolderEditIcon className="w-[12px] h-[12px]" />
      <TypographyXSmall>Rename</TypographyXSmall>
    </ContextMenuItem>
    <ContextMenuSeparator />
    <ContextMenuItem onSelect={onClickDelete} className="flex flex-row gap-[6px] place-items-center">
      <TrashIcon className="w-[12px] h-[12px] text-destructive" />
      <TypographyXSmall className="text-destructive">Delete</TypographyXSmall>
    </ContextMenuItem>
  </ContextMenuContent> : null;

  return <ContextMenu
    onOpenChange={isOpen => {
      setIsContextMenuOpen(isOpen);
      onContextMenuOpenChanged?.(isOpen);
    }}
  >
    <ContextMenuTrigger>
      {content}
    </ContextMenuTrigger>
    {contextMenuContent}
  </ContextMenu>
})


interface FileTreeProps extends React.HTMLAttributes<HTMLDivElement> {
  model: FileTreeModel,
  callbacks: FileTreeCallbacks,
}


export const FileTree = React.forwardRef<HTMLDivElement, FileTreeProps>(({ model, callbacks, ...props }, ref) => {

  const { items, entitiesByItemId, lastModified: modelLastModified, itemsById, perms } = useFileTree(model);
  
  const [selection, setSelection] = useState<FileTreeSelection>({});
  const selectedEntities = useMemo(() => {
    return selection.order?.map(id => entitiesByItemId[id]).filter(x => x !== null) ?? [];
  }, [selection, entitiesByItemId]);
  const [targetEntity, setTargetEntity] = useState<Category | Asset | Task | FileEntry | null>(null);


  const [currentContextMenuId, setCurrentContextMenuId] = useState<string | null>(null);


  function copyLink(link: string) {
    navigator.clipboard.writeText(link);
  }


  // DOGS

  const { render } = useDogs({
    items,
    selectionMode: "multi",
    clearSelectionOnOutsideClick: true,
    rightClickAsPrimary: true,
    onSelectionChanged(selectedIds) {
      const selectedItems = items.filter(item => selectedIds.has(item.__id));
      const selectedEntities = selectedItems.map(item => entitiesByItemId[item.__id]);
      const newSelection = {
        order: selectedEntities.map(x => x.id),
        categories: selectedEntities.filter(x => x.key === "category").map(x => x.id),
        assets: selectedEntities.filter(x => x.key === "asset").map(x => x.id),
        tasks: selectedEntities.filter(x => x.key === "task").map(x => x.id),
        file_entries: selectedEntities.filter(x => x.key === "file_entry").map(x => x.id),
      }
      setSelection(newSelection);
      callbacks.onSelect?.(newSelection);
    },
  });

  const { isDeselectionWithinGroupBlocked, blockDeselectionWithinGroup, blockSelection } = useFileTreeSelection();


  // Modals 

  const [openModals, setOpenModals] = useState({
    deleteMulti: false,
    renameFileEntry: false,
    renameTask: false,
    renameCategory: false,
    renameAsset: false,
  });

  const modals = <>
    <DeleteMultiModal
      open={openModals.deleteMulti}
      onClose={() => {
        setOpenModals(prev => ({ ...prev, deleteMulti: false }));
        blockSelection(false);
      }}
      permissionsContext={perms.context}
      deletables={selectedEntities}
    />
    <RenameFileEntryModal
      open={openModals.renameFileEntry}
      onClose={() => {
        setOpenModals(prev => ({ ...prev, renameFileEntry: false }));
        setTargetEntity(null);
        blockSelection(false);
      }}
      workspace={model.workspace}
      project={model.project}
      file_entry={targetEntity?.key === "file_entry" ? targetEntity : null}
    />
    <RenameTaskModal
      workspace={model.workspace}
      project={model.project}
      open={openModals.renameTask}
      onClose={() => {
        setOpenModals(prev => ({ ...prev, renameTask: false }));
        setTargetEntity(null);
        blockSelection(false);
      }}
      task={targetEntity?.key === "task" ? targetEntity : null}
    />
    <RenameAssetModal
      workspace={model.workspace}
      project={model.project}
      open={openModals.renameAsset}
      onClose={() => {
        setOpenModals(prev => ({ ...prev, renameAsset: false }));
        setTargetEntity(null);
        blockSelection(false);
      }}
      asset={targetEntity?.key === "asset" ? targetEntity : null}
    />
    <RenameCategoryModal
      workspace={model.workspace}
      project={model.project}
      open={openModals.renameCategory}
      onClose={() => {
        setOpenModals(prev => ({ ...prev, renameCategory: false }));
        setTargetEntity(null);
        blockSelection(false);
      }}
      category={targetEntity?.key === "category" ? targetEntity : null}
    />
  </>
  
  return <>
    {modals}
    <TooltipProvider skipDelayDuration={0} delayDuration={0} disableHoverableContent>
      {render({

        root(root) {
          return <div
            key={root.id}
            ref={root.ref}
            {...props}
            className={cn("flex flex-row flex-wrap gap-2 p-4 select-none", props.className)}
          >
            {root.children}
          </div>
        },
        rootRenderDeps(root) {
          return [
            root.id,
            items.map(x => x.__id),
            ...Object.entries(props).sort((a, b) => a[0].localeCompare(b[0])).map(x => x[1]),
          ]
        },

        group(group) {
          return <FolderItem
            key={group.id}
            ref={group.ref}
            className="flex flex-col gap-2"
            item={group as RenderableGroup<FileTreeFileEntry>}
            isContextMenuOpen={currentContextMenuId === group.id}
            onContextMenuOpenChanged={isOpen => {
              setCurrentContextMenuId(isOpen ? group.id : null);
              if (isOpen) {
                blockDeselectionWithinGroup(true);
              } else {
                blockDeselectionWithinGroup(false);
              }
            }}
            onClickDelete={() => {
              setOpenModals(prev => ({ ...prev, deleteMulti: true }));
              blockSelection(true);
            }}
            onClickCopyLink={() => {
              let link: string | undefined;
              if (group.group.data.type === "category") {
                link = callbacks.onCategoryGetLink?.(group.group.data.entity);
              } else if (group.group.data.type === "asset") {
                link = callbacks.onAssetGetLink?.(group.group.data.entity);
              } else if (group.group.data.type === "task") {
                link = callbacks.onTaskGetLink?.(group.group.data.entity);
              }
              if (link) {
                copyLink(link);
              }
            }}
            onClickRename={() => {
              setTargetEntity(group.group.data.entity);
              switch (group.group.data.type) {
                case "category":
                  setOpenModals(prev => ({ ...prev, renameCategory: true }));
                  blockSelection(true);
                  break;
                case "asset":
                  setOpenModals(prev => ({ ...prev, renameAsset: true }));
                  blockSelection(true);
                  break;
                case "task":
                  setOpenModals(prev => ({ ...prev, renameTask: true }));
                  blockSelection(true);
                  break;
                default:
                  break;
              }
            }}
            onOpen={() => {
              switch (group.group.data.type) {
                case "category":
                  callbacks.onCategoryOpen?.(group.group.data.entity);
                  break;
                case "asset":
                  callbacks.onAssetOpen?.(group.group.data.entity);
                  break;
                case "task":
                  callbacks.onTaskOpen?.(group.group.data.entity);
                  break;
                default:
                  break;
              }
            }}
          />
        },
        groupRenderDeps(group) {
          const contextMenuOpen = currentContextMenuId === group.id;
          return [
            group.id,
            group.group.__children.length,
            group.group.data.entity.data.updated_at,
            group.isHovering,
            group.isSelected,
            contextMenuOpen,
            contextMenuOpen ? modelLastModified : null,
            contextMenuOpen ? isDeselectionWithinGroupBlocked : null,
            contextMenuOpen ? blockDeselectionWithinGroup : null,
          ]
        },

        child(child) {
          const selectedSomethingOtherThanFile = (selection.assets?.length ?? 0) > 0 || (selection.tasks?.length ?? 0) > 0 || (selection.categories?.length ?? 0) > 0;
          return <FileItem
            key={child.id}
            ref={child.ref}
            className="flex flex-col gap-2"
            item={child as RenderableChild<FileTreeFileEntry>}
            source={child.item.data.type === "file" ? child.item.data.source : null}
            isMoveDisabled={selectedSomethingOtherThanFile}
            isDownloadDisabled={selectedSomethingOtherThanFile}
            onContextMenuOpenChanged={isOpen => {
              setCurrentContextMenuId(isOpen ? child.id : null);
              if (isOpen) {
                blockDeselectionWithinGroup(true);
              } else {
                blockDeselectionWithinGroup(false);
              }
            }}
            onOpen={() => child.item.data.type === "file" && callbacks.onFileOpen?.(child.item.data.entity)}
            onGetMoveDestinations={callbacks.onGetMoveDestinations}
            onClickCopyLink={() => child.item.data.type === "file" && callbacks.onFileGetLink?.(child.item.data.entity) && copyLink(callbacks.onFileGetLink?.(child.item.data.entity))}
            onClickDownload={() => child.item.data.type === "file" && downloadFile(child.item.data.entity)}
            onClickRename={() => {
              setTargetEntity(child.item.data.entity);
              setOpenModals(prev => ({ ...prev, renameFileEntry: true }));
              blockSelection(true);
            }}
            onClickFileMove={async destination => {
              if (child.item.data.entity.key !== "file_entry") {
                return;
              }
              const itemIds = selection.file_entries?.find(id => id === child.item.data.entity.id) ? selection.file_entries : (child.item.data.entity.key === "file_entry" ? [child.id] : null);
              const targets = itemIds?.map(itemId => itemId in entitiesByItemId && itemId in itemsById && itemsById[itemId].data.type === "file" ? { fileEntry: entitiesByItemId[itemId] as FileEntry, source: (itemsById[itemId] as Item<FileTreeFileEntry>).data.source as Category | Asset | Task } : null).filter(x => x !== null) || [];
              if (!targets) return;
              callbacks?.onFileMove?.(targets as { fileEntry: FileEntry, source: Category | Asset | Task }[], destination);
            }}
            onClickDelete={() => {
              setOpenModals(prev => ({ ...prev, deleteMulti: true }));
              blockSelection(true);
            }}
          />
        },
        childRenderDeps(child) {
          const contextMenuOpen = currentContextMenuId === child.id;
          return [
            child.id,
            child.item.data.entity.data.updated_at,
            child.isHovering,
            child.isSelected,
            contextMenuOpen,
            contextMenuOpen ? modelLastModified : null,
            contextMenuOpen ? (selection.assets?.length ?? 0) > 0 || (selection.tasks?.length ?? 0) > 0 || (selection.categories?.length ?? 0) > 0 : null,
            contextMenuOpen ? isDeselectionWithinGroupBlocked : null,
            contextMenuOpen ? blockDeselectionWithinGroup : null,
          ]
        },

      })}
    </TooltipProvider>
  </>
});
