"use client";

import React from "react";
import { Project, Workspace, uploadFile, validateWorkspaceFile } from "@palette.tools/model.client";
import { ProgressModal } from "../modals/ProgressModal";
import { InfoModal } from "../modals/InfoModal";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/components/ui/tooltip";
import { flushSync } from "react-dom";


export interface FileUploadValidation {
  isValid: boolean;
  reason?: string;
}


export interface FileUploadDetails {
  nonce: string;
  name: string;
  entryId: string | null;
  progress: number;
  size: number;
  index: number;
  file: File;
  validationError: string | null;
  uploadError: string | null;
}


export interface FileUploadTotal {
  fileCount: number;
  progress: number;
  size: number;
  index: number | null;
  details: FileUploadDetails[];
  hasValidationErrors: boolean;
  hasUploadErrors: boolean;
}


export interface FileUploadCallbackParams extends FileUploadDetails {
  total: FileUploadTotal;
}


export const useFileUpload = (
  workspace: Workspace | null,
  project: Project | null,
  onValidateFile: (f: File) => FileUploadValidation = (f) => validateWorkspaceFile(workspace, f),
) => {
  const canUpload = !!workspace && !!project;
  const state = React.useRef<{
    isUploading: boolean;
    progress: number;
    validationError: string;
    uploadError: string;
    index: number | null;
    details: FileUploadDetails[];
  }>({
    isUploading: false,
    progress: 0,
    validationError: "",
    uploadError: "",
    index: null,
    details: [],
  });
  const dispatch = React.useReducer((x: number) => x + 1, 0)[1];

  const upload = React.useCallback(async (
    files: File[],
    callbacks?: {
      onStart?: (params: FileUploadCallbackParams) => void;
      onStartFile?: (params: FileUploadCallbackParams) => void;
      onProgress?: (params: FileUploadCallbackParams) => void;
      onCreateEntry?: (params: FileUploadCallbackParams) => void;
      onFinish?: (params: FileUploadCallbackParams) => void;
      onFinishFile?: (params: FileUploadCallbackParams) => void;
    }
  ) => {
    if (!canUpload || files.length === 0) return;

    // Initialize progress tracking for all files
    const initialDetails: FileUploadDetails[] = files.map((f, index) => ({
      nonce: crypto.randomUUID(),
      name: f.name,
      size: f.size,
      entryId: null,
      progress: 0,
      index,
      file: f,
      validationError: null,
      uploadError: null,
    }));
    flushSync(() => {
      state.current.uploadError = "";
      state.current.isUploading = true;
      state.current.details = initialDetails;
      dispatch();
    });

    // Do validations first
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      const validation = onValidateFile(f);
      if (!validation.isValid) {
        state.current.details[i] = { ...state.current.details[i], validationError: validation.reason || "File cannot be uploaded." };
        dispatch();
      }
    }

    // Do uploads
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      const nonce = initialDetails[i].nonce;
      state.current.index = i;

      const getCallbackParams = () => {
        return {
          ...state.current.details[i],
          total: {
            progress: state.current.progress,
            fileCount: files.length,
            index: i,
            size: state.current.details.reduce((sum, details) => sum + details.size, 0),
            details: state.current.details,
            hasValidationErrors: state.current.details.some(d => d.validationError),
            hasUploadErrors: state.current.details.some(d => d.uploadError),
          },
        };
      };

      const updateProgress = (newProgress: number) => {
        state.current.details[i] = { ...state.current.details[i], progress: newProgress }

        // Calculate total progress using the latest fileProgresses state
        state.current.progress = state.current.details.reduce((sum, details, idx) => {
          return sum + details.progress;
        }, 0) / files.length;

        dispatch();

        callbacks?.onProgress?.(getCallbackParams());

      };

      // Signal start of current file upload
      if (i === 0) {
        callbacks?.onStart?.(getCallbackParams());
      }
      callbacks?.onStartFile?.(getCallbackParams());

      try {
        await uploadFile(
          workspace,
          project,
          f,
          {
            onCreateEntry: (entryId) => {
              state.current.details[i] = { ...state.current.details[i], entryId };
              dispatch();
              
              callbacks?.onCreateEntry?.(getCallbackParams());
            },
            onProgress: (state, bytesTransferred, totalBytes) => {
              const adjusted_progress = ((bytesTransferred / totalBytes) * 0.90) + 0.05;
              updateProgress(adjusted_progress);
            },
            nonce,
          }
        );
      } catch (e) {
        const error = e instanceof Error ? e.message : "Upload failed";
        state.current.details[i] = { ...state.current.details[i], uploadError: error };
        state.current.uploadError = error;
        dispatch();
      } finally {
        callbacks?.onFinishFile?.(getCallbackParams());
        if (i === files.length - 1) {
          callbacks?.onFinish?.(getCallbackParams());
        }
      }
    }

    state.current.isUploading = false;
  }, [workspace, project, onValidateFile, canUpload]);

  const result = React.useMemo(() => ({
    canUpload,
    upload,
    isUploading: state.current.isUploading,
    progress: state.current.progress,
    validationError: state.current.validationError,
    uploadError: state.current.uploadError,
    index: state.current.index,
    details: state.current.details.map(fp => ({ ...fp })),
  }), [
    canUpload, 
    upload, 
    state.current.isUploading, 
    state.current.progress,
    state.current.validationError, 
    state.current.uploadError, 
    state.current.index,
    state.current.details,
  ]);

  return result;
};

const FileUploadContext = React.createContext<{
  isUploading: boolean;
  validationError: string;
  uploadError: string;
  progress: number;
  details: FileUploadDetails[];
  upload: (
    files: File[],
    callbacks?: {
      onStart?: (params: FileUploadCallbackParams) => void;
      onStartFile?: (params: FileUploadCallbackParams) => void;
      onProgress?: (params: FileUploadCallbackParams) => void;
      onCreateEntry?: (params: FileUploadCallbackParams) => void;
      onFinishFile?: (params: FileUploadCallbackParams) => void;
      onFinish?: (params: FileUploadCallbackParams) => void;
    }
  ) => Promise<void>;
} | null>(null);

interface FileUploadProviderProps {
  workspace: Workspace | null;
  project: Project | null;
  children: React.ReactNode;
  blocking: boolean;
  onValidateFile?: (f: File) => FileUploadValidation;
}

export const FileUploadProvider: React.FC<FileUploadProviderProps> = ({
  workspace,
  project,
  children,
  blocking = true,
  onValidateFile = (f) => validateWorkspaceFile(workspace, f),
}) => {
  const { canUpload, progress, validationError, uploadError, isUploading, upload, details } = 
    useFileUpload(workspace, project, onValidateFile);

  return (
    <FileUploadContext.Provider 
      value={{
        isUploading,
        validationError,
        uploadError,
        progress,
        details,
        upload: canUpload ? upload : async () => {},
      }}
    >
      <InfoModal open={!!validationError}>{validationError}</InfoModal>
      {children}
      {isUploading && blocking ? <ProgressModal open={isUploading} progress={progress} error={uploadError}>Uploading...</ProgressModal> : null}
    </FileUploadContext.Provider>
  );
};

const useFileUploadContext = () => {
  const context = React.useContext(FileUploadContext);
  return context;
};

interface FileUploadTriggerProps<T extends React.ElementType> {
  children: React.ReactElement<React.ComponentPropsWithoutRef<T>>;
  disabled?: boolean;
  allowableMimeTypes?: string[];
  multiple?: boolean;
  onStart?: (params: FileUploadCallbackParams) => void;
  onStartFile?: (params: FileUploadCallbackParams) => void;
  onProgress?: (params: FileUploadCallbackParams) => void;
  onCreateEntry?: (params: FileUploadCallbackParams) => void;
  onFinishFile?: (params: FileUploadCallbackParams) => void;
  onFinish?: (params: FileUploadCallbackParams) => void;
}

export const FileUploadTrigger = <T extends React.ElementType>({
  children,
  disabled: _disabled,
  allowableMimeTypes,
  multiple = false,
  onStart,
  onStartFile,
  onProgress,
  onCreateEntry,
  onFinishFile,
  onFinish,
}: FileUploadTriggerProps<T>) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const context = useFileUploadContext();
  const disabled = _disabled || !context;

  const props: typeof children.props = {
    ...children.props,
    onClick() {
      if (disabled || !inputRef.current) return;
      inputRef.current.value = "";
      inputRef.current.click();
    }
  };

  const handleFileChange = async () => {
    if (!inputRef.current || !inputRef.current.files || inputRef.current.files.length < 1 || !context) {
      return;
    }
    const files = Array.from(inputRef.current.files);
    context.upload(files, { onCreateEntry, onStart, onStartFile, onProgress, onFinishFile, onFinish });
  };

  const clone = React.cloneElement(children, props);

  const trigger = disabled
    ? <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>{clone}</TooltipTrigger>
          <TooltipContent className="italic text-muted-foreground">Workspace not set up for uploading.</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    : clone;

  return (
    <>
      <input 
        ref={inputRef} 
        type="file" 
        multiple={multiple}
        style={{ display: "none" }} 
        onChange={handleFileChange} 
        accept={allowableMimeTypes?.join(",")} 
      />
      {trigger}
    </>
  );
};
