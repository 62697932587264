"use client";

import React, { useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium, TypographyXSmall } from "../typography";
import { Entity, PermissionsContext, useCanDelete } from "@palette.tools/model.client";
import { delete_entities } from "@palette.tools/api.client";
import { ChevronDownIcon, ExpandIcon } from "lucide-react";



export const DeleteMultiModal: React.FC<{
  open: boolean,
  onClose: () => void,
  deletables: Entity[],
  permissionsContext: PermissionsContext,
}> = ({
  open,
  onClose,
  deletables,
  permissionsContext,
}) => {

  const { canDelete, nonDeletables } = useCanDelete({ context: permissionsContext, deletables });

  // Regular hooks
  const [isDeleting, setDeleting] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  let content: React.ReactNode;
  if (!canDelete) {
    content = <div className="flex flex-col gap-2 border-[1px] rounded-md cursor-pointer p-2" onClick={e => { setShowDetails(!showDetails); e.preventDefault(); }}>
      <div className="flex items-center gap-2">
        <TypographyXSmall className="font-bold">Details</TypographyXSmall>
        <ChevronDownIcon className={`w-[16px] h-[16px] ${showDetails ? "rotate-180" : ""}`} />
      </div>
      {showDetails && (
        <TypographyXSmall className="text-muted-foreground">
          {nonDeletables.map(d => d[1] + ` (${typeof d[0] === 'string' ? d[0] : (d[0] as any).data.name || d[0].key})`).join("\n")}
        </TypographyXSmall>
      )}
    </div>
  }
  else {
    content = <TypographyMedium>You will delete {deletables.length} item{deletables.length > 1 ? "s" : ""} and all items underneath {deletables.length > 1 ? "them" : "it"}. Are you sure?</TypographyMedium>
  }

  const submitDisabled = (
    !canDelete
    || isDeleting
    || nonDeletables.length > 0
  );

  return <Modal
    open={open}
    header={`Permanently Delete Item${deletables.length > 1 ? "s" : ""}`}
    description={canDelete ? "This CANNOT be undone!" : "You are not allowed to delete these items."}
    primaryButtonClass="bg-destructive hover:bg-destructive/50 text-destructive-foreground"
    primaryButtonContent={isDeleting ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Deleting"}</> : "Delete"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      onClose && onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled) {
        setDeleting(true)
        await delete_entities(deletables.map(d => [d.key, d.id])).finally(() => {
          setDeleting(false);
          onClose && onClose();
        });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
